import React, { type ReactElement } from 'react'
import { Flex, Text, useToast } from '@chakra-ui/react'
import { useLazyQuery } from '@apollo/client'
import { type BankAccountDocumentFragment } from '@/graphql/__generated__/BankAccountDocumentFragment'
import { Color } from '@/theme/theme'
import useInitiateBrowserDownload, { BlobDownloadFileType } from '@/hooks/useInitiateBrowserDownload'
import { getErrorToast } from '@/utils/toastUtils'
import PopoverIconMenu from '@/library/popoverMenu/PopoverIconMenu'
import DownloadIcon from '@/library/icons/DownloadIcon'
import { DateTimeTemplate, getFormattedDateString, TimeZone } from '@/utils/dateUtils'
import {
  type GetBankAccountDocumentSignedUrl,
  type GetBankAccountDocumentSignedUrlVariables
} from '@/graphql/__generated__/GetBankAccountDocumentSignedUrl'
import { GET_BANK_ACCOUNT_DOCUMENT_SIGNED_URL } from '@/graphql/queries/GetBankAccountDocumentSignedUrl'
import { BankDocumentType } from '@/graphql/__generated__/globalTypes'

interface DocumentRowProps {
  document: BankAccountDocumentFragment
}

export default function DocumentRow ({ document }: DocumentRowProps): ReactElement {
  const toast = useToast()
  const errorToastContent = getErrorToast('Unable to download document')

  const { setDownloadUrl: triggerDownload } = useInitiateBrowserDownload({
    fileType: BlobDownloadFileType.PDF,
    filename: getFilename(document),
    onError: () => { toast(errorToastContent) }
  })

  const [
    getSignedUrl,
    { loading }
  ] = useLazyQuery<GetBankAccountDocumentSignedUrl, GetBankAccountDocumentSignedUrlVariables>(
    GET_BANK_ACCOUNT_DOCUMENT_SIGNED_URL,
    {
      onCompleted: (data) => {
        const signedUrl = data.bankAccountDocument.signedUrl
        triggerDownload(signedUrl)
      },
      onError: () => { toast(errorToastContent) }
    }
  )

  return (
    <Flex w='100%' justifyContent='space-between' alignItems='center'>
      <Text color={Color.DARK_BLUE}>{getDocumentDescription(document)}</Text>
      <PopoverIconMenu
        menuOptions={[{
          text: 'Download',
          onClick: () => { void getSignedUrl({ variables: { id: document.id } }) },
          beforeIcon: <DownloadIcon color={Color.DARK_GREY}/>,
          isLoading: loading,
          keepOpenOnSelect: true
        }]}
      />
    </Flex>
  )
}

function getFilename (document: BankAccountDocumentFragment): string {
  switch (document.bankDocumentType) {
    case BankDocumentType.STATEMENT: {
      const date = getFormattedDateString(
        document.documentStartPeriod,
        DateTimeTemplate.MONTH_YEAR,
        TimeZone.DEFAULT_SYSTEM_TIME_ZONE
      )
      return `Amplify Account Statement ${date ?? ''}.pdf`
    }
    case BankDocumentType.TAX_1099: {
      const date = getFormattedDateString(
        document.documentStartPeriod,
        DateTimeTemplate.MONTH_YEAR,
        TimeZone.DEFAULT_SYSTEM_TIME_ZONE
      )
      return `Amplify Account 1099-INT ${date ?? ''}.pdf`
    }
  }
}

function getDocumentDescription (document: BankAccountDocumentFragment): string | null {
  switch (document.bankDocumentType) {
    case BankDocumentType.STATEMENT:
      return getFormattedDateString(
        document.documentStartPeriod,
        DateTimeTemplate.MONTH_YEAR,
        TimeZone.DEFAULT_SYSTEM_TIME_ZONE
      )
    case BankDocumentType.TAX_1099:
      return getFormattedDateString(
        document.documentStartPeriod,
        DateTimeTemplate.YEAR_LONG,
        TimeZone.DEFAULT_SYSTEM_TIME_ZONE
      )
  }
}
