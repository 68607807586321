import React, { type ReactNode, type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { BorderRadius, Color, IconSize } from '../theme/theme'
import ModalCloseHeader from '../modal/ModalCloseHeader'

export enum BannerVariant {
  DARK_BLUE,
  PRIMARY,
  INFO,
  ERROR
}

interface BannerStyles {
  titleColor: string
  subtitleColor: string
  iconColor: string
  backgroundColor: string
  borderColor: string
}

export interface BannerProps {
  title: string
  subtitle: string
  beforeIcon: ReactElement
  callToActionComponent?: ReactNode
  variant?: BannerVariant
  onCollapse?: () => void
}

export default function Banner ({
  title,
  subtitle,
  beforeIcon,
  callToActionComponent,
  variant = BannerVariant.PRIMARY,
  onCollapse
}: BannerProps): ReactElement {
  const {
    titleColor,
    subtitleColor,
    iconColor,
    backgroundColor,
    borderColor
  } = getBannerStyles(variant)

  const beforeIconWithProps = beforeIcon != null
    ? React.cloneElement(beforeIcon, { color: iconColor })
    : null

  return (
    <Flex
      borderRadius={BorderRadius.CARD}
      p={4}
      gap={4}
      flexDirection='row'
      width='100%'
      backgroundColor={backgroundColor}
      border={`1px solid ${borderColor}`}
    >
      <Flex gap={4} justifyContent='flex-start' flex={9}>
        <Flex justifyContent='center' alignItems='center'>
          {beforeIconWithProps}
        </Flex>
        <Flex flexDirection='column' gap={1}>
          <Heading size='sm' color={titleColor}>{title}</Heading>
          <Text color={subtitleColor}>{subtitle}</Text>
        </Flex>
      </Flex>
      <Flex alignItems='center'>
        {callToActionComponent}
      </Flex>
      {
            onCollapse != null &&
            <Flex>
              <ModalCloseHeader onClose={() => { onCollapse() }} iconSize={IconSize.SMALL}/>
            </Flex>
        }
    </Flex>
  )
}

function getBannerStyles (variant: BannerVariant): BannerStyles {
  switch (variant) {
    case BannerVariant.PRIMARY:
      return {
        titleColor: Color.DARK_BLUE,
        subtitleColor: Color.DARK_GREY,
        iconColor: Color.BRIGHT_BLUE,
        backgroundColor: Color.WHITE,
        borderColor: Color.BRIGHT_BLUE
      }
    case BannerVariant.ERROR:
      return {
        titleColor: Color.DARK_BLUE,
        subtitleColor: Color.DARK_GREY,
        iconColor: Color.ERROR_RED,
        backgroundColor: Color.WHITE,
        borderColor: Color.WHITE
      }
    case BannerVariant.DARK_BLUE:
      return {
        titleColor: Color.WHITE,
        subtitleColor: Color.WHITE,
        iconColor: Color.WHITE,
        backgroundColor: Color.DARK_BLUE,
        borderColor: Color.DARK_BLUE
      }
    case BannerVariant.INFO:
      return {
        titleColor: Color.DARK_BLUE,
        subtitleColor: Color.DARK_GREY,
        iconColor: Color.DARK_BLUE,
        backgroundColor: Color.WHITE,
        borderColor: Color.DARK_BLUE
      }
  }
}
