import { Center, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { DisclosureText, getAPYDisclaimerFootNote } from './disclosureText'
import AltirSkeleton from '../loading/AltirSkeleton'
import { FontWeight } from '@/theme/theme'
import { type GetAmplifyPromotionalData } from '@/graphql/__generated__/GetAmplifyPromotionalData'
import { GET_AMPLIFY_PROMOTIONAL_DATA } from '@/graphql/queries/GetAmplifyPromotionalData'

export default function OnboardingDisclosureFooter (): ReactElement {
  const {
    data,
    loading,
    error
  } = useQuery<GetAmplifyPromotionalData>(GET_AMPLIFY_PROMOTIONAL_DATA)

  const APYDisclaimer = getAPYDisclaimerFootNote(
    data?.amplifyAccountPromotionalOffer?.subscribedAnnualPercentageYield ?? null
  )

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={3} w='100%'>
        <Text fontSize='sm' fontWeight={FontWeight.NORMAL}>
          {APYDisclaimer}
        </Text>
        <Text fontSize='sm' fontWeight={FontWeight.NORMAL}>
          {DisclosureText.ALTIR_IS_NOT_A_BANK}
        </Text>
        <Text fontSize='sm' fontWeight={FontWeight.NORMAL}>
          {DisclosureText.INTRAFI_DISCLAIMER}
        </Text>
      </Center>
    </AltirSkeleton>
  )
}
