import { gql } from '@apollo/client'
import { FINANCIAL_ACCOUNT_SHORT_FRAGMENT } from '../fragments/financial_accounts/FinancialAccountShortFragment'

export const GET_HOME_PAGE_DATA = gql`
    ${FINANCIAL_ACCOUNT_SHORT_FRAGMENT}
    query GetHomePageData($organizationId: String, $financialAccountFilter: FinancialAccountFilterInput) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                id
                name
                franchiseGroups {
                    id
                    name
                    amplifyAccountApplicationStatus
                    financialAccounts(financialAccountFilter: $financialAccountFilter) {
                        ...FinancialAccountShortFragment
                    }
                    aggregateAccountBalance(financialAccountFilter: $financialAccountFilter) {
                        availableBalance {
                            amount
                        }
                    }
                }
                users {
                    firstName
                    lastName
                }
            }
        }
    }  
`
