import React, { type ReactElement } from 'react'
import { Flex, Link, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { Color, FontWeight } from '../theme/theme'
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../theme/urls'
import { DisclosureText, getAPYDisclaimerAsterisk } from '../legal/disclosureText'
import AltirSkeleton from '../loading/AltirSkeleton'
import { type GetAmplifyPromotionalData } from '@/graphql/__generated__/GetAmplifyPromotionalData'
import { GET_AMPLIFY_PROMOTIONAL_DATA } from '@/graphql/queries/GetAmplifyPromotionalData'

export interface DisclosureLinkTextProps {
  text: string
  link?: string
}

function DisclosureLinkText ({ text, link }: DisclosureLinkTextProps): ReactElement {
  return (
    <Link href={link} isExternal>
      <Text fontWeight={FontWeight.MEDIUM} color={Color.GREY_BLUE}>
        {text}
      </Text>
    </Link>
  )
}

interface DisclosureFooterProps {
  backgroundColor?: Color
}

export default function DisclosureFooter ({
  backgroundColor = Color.GREY
}: DisclosureFooterProps): ReactElement {
  const {
    data,
    loading,
    error
  } = useQuery<GetAmplifyPromotionalData>(GET_AMPLIFY_PROMOTIONAL_DATA)

  const APYDisclaimer = getAPYDisclaimerAsterisk(
    data?.amplifyAccountPromotionalOffer?.subscribedAnnualPercentageYield ?? null
  )

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Flex
        justifyContent='start'
        alignItems='start'
        textAlign='start'
        width='100%'
        backgroundColor={backgroundColor}
        flexDirection='column'
        px={24}
        py={6}
        gap={4}
      >
        <Flex gap={2}>
          <DisclosureLinkText text='Terms of Service' link={TERMS_OF_SERVICE_URL}/>
          <Text fontWeight={FontWeight.MEDIUM} fontSize='lg' color={Color.GREY_BLUE}>
            |
          </Text>
          <DisclosureLinkText text='Privacy Policy' link={PRIVACY_POLICY_URL}/>
        </Flex>
        <Text color={Color.DARK_BLUE} fontWeight={FontWeight.NORMAL} fontSize='xs'>
          {DisclosureText.ALTIR_IS_NOT_A_BANK}
        </Text>
        <Text color={Color.DARK_BLUE} fontWeight={FontWeight.NORMAL} fontSize='xs'>
          {APYDisclaimer}
        </Text>
        <Text color={Color.DARK_BLUE} fontWeight={FontWeight.NORMAL} fontSize='xs'>
          {DisclosureText.INTRAFI_DISCLAIMER}
        </Text>
      </Flex>
    </AltirSkeleton>
  )
}
