import React, { type ReactElement } from 'react'
import { Center, Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import DocumentSection from './DocumentSection'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { GET_BANK_ACCOUNT_DOCUMENTS } from '@/graphql/queries/GetBankAccountDocuments'
import {
  type GetBankAccountDocuments,
  type GetBankAccountDocumentsVariables
} from '@/graphql/__generated__/GetBankAccountDocuments'
import { BankDocumentType } from '@/graphql/__generated__/globalTypes'
import { type BankAccountDocumentFragment } from '@/graphql/__generated__/BankAccountDocumentFragment'

export interface AccountActivityComponentProps {
  accountId: number
}

export default function AccountDocumentsComponent (
  {
    accountId
  }: AccountActivityComponentProps
): ReactElement<string, string> {
  const {
    data,
    loading,
    error
  } = useQuery<GetBankAccountDocuments, GetBankAccountDocumentsVariables>(
    GET_BANK_ACCOUNT_DOCUMENTS,
    {
      variables: {
        accountId
      }
    }
  )

  const allDocuments = data?.financialAccount.bankDocuments ?? []

  const tax1099Documents = getDocumentsOfType(allDocuments, BankDocumentType.TAX_1099)

  return (
    <Flex flexDirection='column'>
      <Text>Documents</Text>
      <AltirSkeleton isLoading={loading} error={error} marginY={3}>
        <Center w='100%' flexDir='column' alignItems='start' gap={3}>
          {
            tax1099Documents.length > 0 &&
              <DocumentSection
                type={BankDocumentType.TAX_1099}
                documents={tax1099Documents}
              />
          }
          <DocumentSection
            type={BankDocumentType.STATEMENT}
            documents={getDocumentsOfType(allDocuments, BankDocumentType.STATEMENT)}
          />
        </Center>
      </AltirSkeleton>
    </Flex>
  )
}

function getDocumentsOfType (
  documents: BankAccountDocumentFragment[],
  type: BankDocumentType
): BankAccountDocumentFragment[] {
  return documents.filter(document => document.bankDocumentType === type)
}
