export const ASSET_HOSTING_URL = 'https://assets.altir.app'

export const ACH_AUTHORIZATION_URL = `${ASSET_HOSTING_URL}/assets/documents/ach_authorization_agreement_12_20_2024.pdf`
export const WIRE_AUTHORIZATION_URL = `${ASSET_HOSTING_URL}/assets/documents/wire_authorization_agreement_12_20_2024.pdf`
export const E_SIGN_AGREEMENT_URL = `${ASSET_HOSTING_URL}/assets/electronic_consent_11_17_2023.pdf`
export const PRIVACY_POLICY_URL = `${ASSET_HOSTING_URL}/assets/altir_privacy_policy_12_16_2024.pdf`
export const TERMS_OF_SERVICE_URL = `${ASSET_HOSTING_URL}/assets/altir_terms_of_services_11_21_2024.pdf`
export const ALTIR_RATE_AND_FEES_SHEET_URL = `${ASSET_HOSTING_URL}/assets/documents/altir_rate_and_fees_sheet_12_19_2024.pdf`

// Grasshopper Docs
export const GRASSHOPPER_CUSTOMER_AGREEMENT_URL = `${ASSET_HOSTING_URL}/assets/grasshopper_bank_master_services_agreement_11_12_2024.pdf`
export const GRASSHOPPER_ICS_DEPOSIT_PLACEMENT_URL = `${ASSET_HOSTING_URL}/assets/grasshopper_ics_deposit_placement_agreement_11_14_2024.pdf`
