import React, { useState, type ReactElement } from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import DocumentRow from './DocumentRow'
import { BankDocumentType } from '@/graphql/__generated__/globalTypes'
import { type BankAccountDocumentFragment } from '@/graphql/__generated__/BankAccountDocumentFragment'
import { BorderRadius, Color } from '@/theme/theme'
import PopoverMenu from '@/library/popoverMenu/components/PopoverMenu'
import PopoverMenuSingleOptionSelect from '@/library/popoverMenu/PopoverMenuSingleOptionSelect'
import { DateTimeTemplate, getFormattedDateString, TimeZone } from '@/utils/dateUtils'
import { nonNull, unique } from '@/utils/arrayUtils'

interface DocumentSectionProps {
  type: BankDocumentType
  documents: BankAccountDocumentFragment[]
}

export default function DocumentSection ({
  type,
  documents
}: DocumentSectionProps): ReactElement {
  const yearOptions = getYearOptions(documents)
  const [yearSelectionOptions] = useState<string[]>(yearOptions)
  // Hide year selector for 1099s since there are usually only one per year
  const [selectedYear, setSelectedYear] = useState<string | undefined>(
    type !== BankDocumentType.TAX_1099 ? yearOptions[yearOptions.length - 1] : undefined
  )

  const displayedDocuments = filterDocumentsByYear(documents, selectedYear)

  const { title, subtitle, note } = getMetadata(type, displayedDocuments)

  return (
    <Flex
      width='100%'
      flexDirection='column'
      p={6}
      borderRadius={BorderRadius.CARD}
      bg={Color.WHITE}
      gap={6}
    >
      <Flex
        justifyContent='space-between'
        gap={4}
      >
        <Flex flexDir='column'>
          <Text color={Color.DARK_BLUE}>{title}</Text>
          <Text>{subtitle}</Text>
          {note != null && <Text fontStyle='italic'>{note}</Text>}
        </Flex>
        {
          selectedYear != null &&
            <Flex>
              <PopoverMenu
                popoverMenuTitle={selectedYear ?? 'Select Year'}
                onSubmit={() => {}}
                border={`1px solid ${Color.DARK_BLUE}`}
                borderRadius={BorderRadius.BUTTON}
                shouldMatchWidth={true}
                allowInteriorScroll={true}
              >
                <PopoverMenuSingleOptionSelect
                  options={yearSelectionOptions}
                  setSelectedOptionOrUndefined={(year) => { setSelectedYear(year) }}
                  selectedOption={selectedYear}
                  formattingFunction={(year) => { return { title: year } }}
                />
              </PopoverMenu>
            </Flex>
        }
      </Flex>
      <Flex flexDir='column' gap={4}>
        {displayedDocuments.map((document, index) => {
          return (
            <Flex key={index} flexDir='column' gap={4}>
              <DocumentRow document={document}/>
              {index !== displayedDocuments.length - 1 && <Divider/>}
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}

function filterDocumentsByYear (
  documents: BankAccountDocumentFragment[],
  selectedYear: string | undefined
): BankAccountDocumentFragment[] {
  if (selectedYear == null) return documents

  return documents.filter(document => {
    const documentYear = getFormattedDateString(
      document.documentStartPeriod,
      DateTimeTemplate.YEAR_LONG,
      TimeZone.DEFAULT_SYSTEM_TIME_ZONE
    )
    return documentYear === selectedYear
  })
}

function getYearOptions (documents: BankAccountDocumentFragment[]): string[] {
  return unique(
    nonNull(
      documents.map(document => getFormattedDateString(
        document.documentStartPeriod, DateTimeTemplate.YEAR_LONG, TimeZone.DEFAULT_SYSTEM_TIME_ZONE
      ))
    )
  )
}

function getMetadata (
  type: BankDocumentType,
  documents: BankAccountDocumentFragment[]
): { title: string, subtitle: string, note?: string } {
  switch (type) {
    case BankDocumentType.STATEMENT:
      return {
        title: 'Statements',
        subtitle: 'Download monthly account statements'
      }
    case BankDocumentType.TAX_1099: {
      const hasTwo2024Documents = documents.filter(doc => doc.documentStartPeriod.includes('2024')).length > 1
      return {
        title: '1099s',
        subtitle: 'Download 1099-INTs',
        note: hasTwo2024Documents
          ? 'Note: accounts that underwent the account upgrade will have two 1099-INTs for 2024.'
          : undefined
      }
    }
  }
}
