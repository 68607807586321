
import { gql } from '@apollo/client'

export const GET_ONBOARDING_ORGANIZATION_AND_PROMOTIONAL_RATE = gql`
    query GetOnboardingOrganizationAndPromotionalRate {
        currentUser {
            id
            firstName
            lastName
            selectedOrganization {
                id
                name
                franchiseGroups {
                  id
                  name
                  amplifyAccountApplicationStatus
                }
            }
        }
        amplifyAccountPromotionalOffer {
            subscribedAnnualPercentageYield
            unsubscribedAnnualPercentageYield
        }
    }
`
