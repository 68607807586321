/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountInfoUpdateField {
  BUSINESS_AS_NAME = "BUSINESS_AS_NAME",
  BUSINESS_DESCRIPTION = "BUSINESS_DESCRIPTION",
  BUSINESS_EMAIL = "BUSINESS_EMAIL",
  BUSINESS_ESTABLISHMENT_DATE = "BUSINESS_ESTABLISHMENT_DATE",
  BUSINESS_INCORPORATION_STATE = "BUSINESS_INCORPORATION_STATE",
  BUSINESS_LEGAL_STRUCTURE = "BUSINESS_LEGAL_STRUCTURE",
  BUSINESS_MAILING_ADDRESS = "BUSINESS_MAILING_ADDRESS",
  BUSINESS_NAICS_DESCRIPTION = "BUSINESS_NAICS_DESCRIPTION",
  BUSINESS_NAICS_SECTOR_CODE = "BUSINESS_NAICS_SECTOR_CODE",
  BUSINESS_NAME = "BUSINESS_NAME",
  BUSINESS_PHONE_NUMBER = "BUSINESS_PHONE_NUMBER",
  BUSINESS_PHYSICAL_ADDRESS = "BUSINESS_PHYSICAL_ADDRESS",
  BUSINESS_URLS = "BUSINESS_URLS",
  PERSON_BUSINESS_TITLE = "PERSON_BUSINESS_TITLE",
  PERSON_CITIZENSHIP = "PERSON_CITIZENSHIP",
  PERSON_DATE_OF_BIRTH = "PERSON_DATE_OF_BIRTH",
  PERSON_EMAIL = "PERSON_EMAIL",
  PERSON_FIRST_NAME = "PERSON_FIRST_NAME",
  PERSON_LAST_NAME = "PERSON_LAST_NAME",
  PERSON_MAILING_ADDRESS = "PERSON_MAILING_ADDRESS",
  PERSON_MIDDLE_NAME = "PERSON_MIDDLE_NAME",
  PERSON_OCCUPATION = "PERSON_OCCUPATION",
  PERSON_OWNERSHIP_PERCENTAGE = "PERSON_OWNERSHIP_PERCENTAGE",
  PERSON_PHONE_NUMBER = "PERSON_PHONE_NUMBER",
  PERSON_PHYSICAL_ADDRESS = "PERSON_PHYSICAL_ADDRESS",
  PERSON_ROLE = "PERSON_ROLE",
  PERSON_SUFFIX = "PERSON_SUFFIX",
}

export enum AccountLinkStatus {
  DELETED = "DELETED",
  ERROR = "ERROR",
  HEALTHY = "HEALTHY",
  LOGIN_REQUIRED = "LOGIN_REQUIRED",
  PENDING = "PENDING",
  UPDATE_REQUIRED = "UPDATE_REQUIRED",
}

export enum AccountType {
  CHECKING = "CHECKING",
  SAVINGS = "SAVINGS",
}

export enum AltirSubscriptionLevel {
  FREE = "FREE",
  PRO = "PRO",
}

export enum AmplifyAccountApplicationRole {
  CONTROL_PERSON = "CONTROL_PERSON",
  SIGNER = "SIGNER",
}

export enum AmplifyAccountApplicationStatus {
  APPROVED = "APPROVED",
  CREATED = "CREATED",
  DELETED = "DELETED",
  DOES_NOT_EXIST = "DOES_NOT_EXIST",
  ERROR = "ERROR",
  MANUAL_REVIEW = "MANUAL_REVIEW",
  PROCESSING = "PROCESSING",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
}

export enum AmplifyAccountBusinessLegalStructure {
  ccorp = "ccorp",
  corp = "corp",
  estate = "estate",
  foreign_entity = "foreign_entity",
  llc = "llc",
  llp = "llp",
  lp = "lp",
  nonprofit = "nonprofit",
  partnership = "partnership",
  scheme = "scheme",
  scorp = "scorp",
  soleprop = "soleprop",
  trust = "trust",
}

export enum AmplifyAccountIntendedFunction {
  OPERATING_ACCOUNT = "OPERATING_ACCOUNT",
  OTHER = "OTHER",
  PAYROLL_ACCOUNT = "PAYROLL_ACCOUNT",
  SAVINGS_ACCOUNT = "SAVINGS_ACCOUNT",
}

export enum AmplifyAccountStatus {
  CLOSED = "CLOSED",
  CLOSURE_REQUESTED = "CLOSURE_REQUESTED",
  LOCKED = "LOCKED",
  OPEN = "OPEN",
  PENDING = "PENDING",
}

export enum AuthResource {
  ADMIN = "ADMIN",
  AMPLIFY_ACCOUNT = "AMPLIFY_ACCOUNT",
  AMPLIFY_ACCOUNT_APPLICATION = "AMPLIFY_ACCOUNT_APPLICATION",
  AMPLIFY_ACCOUNT_BUSINESS_APPLICATION = "AMPLIFY_ACCOUNT_BUSINESS_APPLICATION",
  AMPLIFY_ACCOUNT_PERSONAL_APPLICATION = "AMPLIFY_ACCOUNT_PERSONAL_APPLICATION",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  BANK_ACCOUNT_DOCUMENT = "BANK_ACCOUNT_DOCUMENT",
  BILLING = "BILLING",
  CARD = "CARD",
  COUNTERPARTY = "COUNTERPARTY",
  FILE_UPLOAD = "FILE_UPLOAD",
  FINANCIAL_INSTITUTION_LINK_PLAID = "FINANCIAL_INSTITUTION_LINK_PLAID",
  FINANCIAL_INSTITUTION_LINK_TELLER = "FINANCIAL_INSTITUTION_LINK_TELLER",
  FRANCHISE_GROUP = "FRANCHISE_GROUP",
  ORGANIZATION = "ORGANIZATION",
  PLAID_ITEM = "PLAID_ITEM",
  PRE_APPROVAL_OFFER = "PRE_APPROVAL_OFFER",
  SELF = "SELF",
  STORE_LOCATION = "STORE_LOCATION",
  USER = "USER",
  USER_INVITE = "USER_INVITE",
}

export enum BankAccountExtensionFilter {
  ALL_ACCOUNTS = "ALL_ACCOUNTS",
  ALL_AMPLIFY_ACCOUNTS = "ALL_AMPLIFY_ACCOUNTS",
  COUNTERPARTIES = "COUNTERPARTIES",
  COUNTERPARTIES_AND_AMPLIFY = "COUNTERPARTIES_AND_AMPLIFY",
  COUNTERPARTIES_PENDING_VERIFICATION = "COUNTERPARTIES_PENDING_VERIFICATION",
  EXLCUDE_AMPLIFY = "EXLCUDE_AMPLIFY",
}

export enum BankDocumentType {
  STATEMENT = "STATEMENT",
  TAX_1099 = "TAX_1099",
}

export enum BankPartner {
  FIRST_BANK = "FIRST_BANK",
  GRASSHOPPER = "GRASSHOPPER",
}

export enum CapitalOSAccountStatus {
  APPROVED = "APPROVED",
  CLOSED = "CLOSED",
  DECLINED = "DECLINED",
  ELIGIBLE = "ELIGIBLE",
  ENGAGED = "ENGAGED",
  INELIGIBLE = "INELIGIBLE",
  ONBOARDING = "ONBOARDING",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
}

export enum CheckEnablementStatus {
  DENIED = "DENIED",
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  REQUESTED = "REQUESTED",
}

export enum CounterpartyType {
  ALTIR_CONNECT = "ALTIR_CONNECT",
  EXTERNAL_PERSONAL = "EXTERNAL_PERSONAL",
  EXTERNAL_VENDOR = "EXTERNAL_VENDOR",
  PLAID = "PLAID",
  TREASURE = "TREASURE",
}

export enum DerivedTransactionCategory {
  AUTOMATED_INTERNAL_TRANSFER = "AUTOMATED_INTERNAL_TRANSFER",
  CAPITAL_INVESTMENT = "CAPITAL_INVESTMENT",
  CATERING_INCOME = "CATERING_INCOME",
  CC_EXPENSE = "CC_EXPENSE",
  COGS = "COGS",
  DELIVERY_INCOME = "DELIVERY_INCOME",
  ERROR = "ERROR",
  EXTERNALLY_INITIATED_DEPOSIT = "EXTERNALLY_INITIATED_DEPOSIT",
  EXTERNALLY_INITIATED_TRANSFER = "EXTERNALLY_INITIATED_TRANSFER",
  EXTERNALLY_INITIATED_WITHDRAWAL = "EXTERNALLY_INITIATED_WITHDRAWAL",
  EXTERNAL_TRANSFER = "EXTERNAL_TRANSFER",
  FINANCING_DEPOSIT = "FINANCING_DEPOSIT",
  FINANCING_PAYMENT = "FINANCING_PAYMENT",
  INTERNAL_TRANSFER = "INTERNAL_TRANSFER",
  LOAN_REPAYMENT = "LOAN_REPAYMENT",
  OTHER_DEPOSIT = "OTHER_DEPOSIT",
  OTHER_INCOME = "OTHER_INCOME",
  OTHER_OPERATING_EXPENSE = "OTHER_OPERATING_EXPENSE",
  OTHER_TRANSFER = "OTHER_TRANSFER",
  PAYROLL = "PAYROLL",
  POS_INCOME = "POS_INCOME",
  RENT = "RENT",
  ROYALTY = "ROYALTY",
  SALES_TAX = "SALES_TAX",
  SERVICE_FEE = "SERVICE_FEE",
  TAX_EXPENSE = "TAX_EXPENSE",
  THIRD_PARTY_INCOME = "THIRD_PARTY_INCOME",
  UNKNOWN = "UNKNOWN",
  UTILITIES = "UTILITIES",
}

export enum EmailVerificationStatus {
  SKIPPED = "SKIPPED",
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
  VERIFYING = "VERIFYING",
}

export enum FileUploadStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export enum FileUploadType {
  BANK_STATEMENT = "BANK_STATEMENT",
  BUSINESS_DEBT_SCHEDULE = "BUSINESS_DEBT_SCHEDULE",
  COUNTERPARTY_VERIFICATION_EVIDENCE = "COUNTERPARTY_VERIFICATION_EVIDENCE",
  GENERIC_LENDING_DOCUMENT = "GENERIC_LENDING_DOCUMENT",
  OTHER = "OTHER",
  PERSONAL_FINANCIAL_STATEMENT = "PERSONAL_FINANCIAL_STATEMENT",
  TAX_RETURN = "TAX_RETURN",
  TRANSACTION_EXPORT = "TRANSACTION_EXPORT",
}

export enum GraphQLErrorCode {
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR",
  CHECK_ID_FIELD_ALREADY_IN_USE = "CHECK_ID_FIELD_ALREADY_IN_USE",
  DUPLICATE_ACCOUNT = "DUPLICATE_ACCOUNT",
  DUPLICATE_TRANSFER_ERROR = "DUPLICATE_TRANSFER_ERROR",
  EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS",
  EMAIL_VERIFICATION_EXPIRED = "EMAIL_VERIFICATION_EXPIRED",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  INTERNAL_SEVER_ERROR = "INTERNAL_SEVER_ERROR",
  INVALID_EMAIL = "INVALID_EMAIL",
  INVALID_TRANSFER_PARAMS = "INVALID_TRANSFER_PARAMS",
  MAX_TRANSFER_RULES_REACHED = "MAX_TRANSFER_RULES_REACHED",
  OWNERSHIP_PERCENTAGE_UNCONFIRMED = "OWNERSHIP_PERCENTAGE_UNCONFIRMED",
  PASSCODE_MISMATCH = "PASSCODE_MISMATCH",
  PASSCODE_RETRIES_EXCEEDED = "PASSCODE_RETRIES_EXCEEDED",
  POS_ID_FIELD_ALREADY_IN_USE = "POS_ID_FIELD_ALREADY_IN_USE",
  TRANSFER_LIMIT_EXCEEDED = "TRANSFER_LIMIT_EXCEEDED",
  TRANSFER_RULE_CIRCULAR_DEPENDENCY = "TRANSFER_RULE_CIRCULAR_DEPENDENCY",
  TRANSFER_RULE_FOR_DELINKED_ACCOUNT = "TRANSFER_RULE_FOR_DELINKED_ACCOUNT",
  TRANSFER_RULE_MISSING_PARAMS = "TRANSFER_RULE_MISSING_PARAMS",
  TRANSFER_STATUS_INELIGIBLE = "TRANSFER_STATUS_INELIGIBLE",
  UNVERIFIED_COUNTERPARTY = "UNVERIFIED_COUNTERPARTY",
  USER_ALREADY_HAS_ORGANIZATION = "USER_ALREADY_HAS_ORGANIZATION",
  USER_TO_AMPLIFY_APPLICANT_MISMATCH = "USER_TO_AMPLIFY_APPLICANT_MISMATCH",
}

export enum InstitutionConnectionProvider {
  ALTIR = "ALTIR",
  PLAID = "PLAID",
  TELLER = "TELLER",
  TREASURY_PRIME = "TREASURY_PRIME",
}

export enum JobExecutionStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export enum LinkedAccountType {
  ACCOUNTING_SOFTWARE = "ACCOUNTING_SOFTWARE",
  BANK = "BANK",
  CARD = "CARD",
  POINT_OF_SALE = "POINT_OF_SALE",
}

export enum LoanStatus {
  ACCEPTED = "ACCEPTED",
  APPLIED = "APPLIED",
  CREATED = "CREATED",
  DISABLED = "DISABLED",
  ENGAGED = "ENGAGED",
  REJECTED = "REJECTED",
  VIEWED = "VIEWED",
}

export enum LoanType {
  LINE_OF_CREDIT = "LINE_OF_CREDIT",
  TERM_LOAN = "TERM_LOAN",
}

export enum OnboardingCTAStep {
  COMPLETE = "COMPLETE",
  CONTINUE_AMPLIFY_APP = "CONTINUE_AMPLIFY_APP",
  CREATE_TRANSFER = "CREATE_TRANSFER",
  CREATE_TRANSFER_RULE = "CREATE_TRANSFER_RULE",
  LINK_ACCOUNT = "LINK_ACCOUNT",
  START_AMPLIFY_APP = "START_AMPLIFY_APP",
  START_CARD_APP = "START_CARD_APP",
}

export enum OnboardingStep {
  ACKNOWLEDGE_COMPLETION = "ACKNOWLEDGE_COMPLETION",
  AMPLIFY_APPLICATION = "AMPLIFY_APPLICATION",
  BUSINESS_CREATION = "BUSINESS_CREATION",
  COMPLETE = "COMPLETE",
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
  ORGANIZATION_INVITE_ACCEPTANCE = "ORGANIZATION_INVITE_ACCEPTANCE",
  ORGANIZTION_CREATION = "ORGANIZTION_CREATION",
  STRIPE_SUBSCRIPTION = "STRIPE_SUBSCRIPTION",
  USER_INFO = "USER_INFO",
}

export enum PlatformEventType {
  BANK_CONNECTION = "BANK_CONNECTION",
  SKIP_ONBOARDING_STEP = "SKIP_ONBOARDING_STEP",
  USER_LOGIN = "USER_LOGIN",
  USER_ORGANIZATION_ROLE_MUTATION = "USER_ORGANIZATION_ROLE_MUTATION",
  USER_ROLE_MUTATION = "USER_ROLE_MUTATION",
}

export enum PointOfSalePlatform {
  ABC_FITNESS_SOLUTIONS = "ABC_FITNESS_SOLUTIONS",
  MINDBODY = "MINDBODY",
}

export enum RevisionRequestType {
  BUSINESS = "BUSINESS",
  PERSON = "PERSON",
}

export enum Role {
  ADMIN = "ADMIN",
  GOD_ADMIN = "GOD_ADMIN",
  MEMBER = "MEMBER",
  NO_ACCESS = "NO_ACCESS",
  OWNER = "OWNER",
  VIEWER = "VIEWER",
}

export enum TrackedJobType {
  BALANCE_REFRESH = "BALANCE_REFRESH",
  TRANSACTION_EXPORT = "TRANSACTION_EXPORT",
  TRANSACTION_PULL = "TRANSACTION_PULL",
  TRANSACTION_RECALCULATE = "TRANSACTION_RECALCULATE",
  TRANSACTION_REFRESH = "TRANSACTION_REFRESH",
}

export enum TransactionSortType {
  Amount = "Amount",
  Date = "Date",
}

export enum TransferCancellationType {
  CANCELED = "CANCELED",
  NONE = "NONE",
  STOP_PAYMENT = "STOP_PAYMENT",
}

export enum TransferDirection {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
}

export enum TransferRuleErrorType {
  LOGIN = "LOGIN",
  LOGIN_RECENTLY_RESOLVED = "LOGIN_RECENTLY_RESOLVED",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export enum TransferRuleLastEvalResult {
  ERROR = "ERROR",
  INITIATED = "INITIATED",
  SKIPPED = "SKIPPED",
  UNKNOWN = "UNKNOWN",
}

export enum TransferRuleType {
  DAILY = "DAILY",
  MIN_TARGET_BALANCE = "MIN_TARGET_BALANCE",
  MONTHLY = "MONTHLY",
  ONE_TIME = "ONE_TIME",
  TARGET_BALANCE = "TARGET_BALANCE",
  WEEKLY = "WEEKLY",
}

export enum TransferStatus {
  AWAITING_BALANCE = "AWAITING_BALANCE",
  CANCELED = "CANCELED",
  CHECK_CLEARED = "CHECK_CLEARED",
  CHECK_EXPIRED = "CHECK_EXPIRED",
  CHECK_STOP_PAYMENT = "CHECK_STOP_PAYMENT",
  CHECK_STOP_PAYMENT_PENDING = "CHECK_STOP_PAYMENT_PENDING",
  CREATED = "CREATED",
  ERROR = "ERROR",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  RECEIVED = "RECEIVED",
  RETURNED = "RETURNED",
  RETURN_SETTLED = "RETURN_SETTLED",
  SENT = "SENT",
  UNKNOWN = "UNKNOWN",
}

export enum TransferType {
  BOOK = "BOOK",
  CHECK = "CHECK",
  SAMEDAY_ACH = "SAMEDAY_ACH",
  STANDARD_ACH = "STANDARD_ACH",
  WIRE = "WIRE",
}

export interface AchParams {
  accountNumber?: string | null;
  accountType: AccountType;
  routingNumber?: string | null;
}

export interface BusinessApplicationSensitiveInput {
  businessApplicationId: string;
  tin: string;
}

export interface CheckParams {
  address: updateAddressUnderscore;
  name: string;
}

export interface CreateTransferInput {
  amount: number;
  amplifyAccountId: string;
  checkMemo?: string | null;
  checkMessage?: string | null;
  counterpartyId: string;
  customDescription?: string | null;
  direction: TransferDirection;
  type?: TransferType | null;
}

export interface CreateTransferRuleInput {
  amplifyAccountId: string;
  counterpartyId: string;
  direction: TransferDirection;
  isActive: boolean;
  minAccountBalanceBeforeWithdrawal?: number | null;
  minTransferAmount?: number | null;
  ruleEndDate?: string | null;
  ruleStartDate: string;
  targetBalance?: number | null;
  targetDate?: number | null;
  targetDay?: number | null;
  transferAmount?: number | null;
  transferRuleType: TransferRuleType;
}

export interface CreateUserInviteInput {
  businessIds?: number[] | null;
  email: string;
  organizationId: string;
  role: Role;
}

export interface DateRangeInput {
  endDate?: string | null;
  startDate?: string | null;
}

export interface FinancialAccountFilterInput {
  accountType?: LinkedAccountType | null;
  bankAccountExtensionFilter?: BankAccountExtensionFilter | null;
  connectionProvider?: InstitutionConnectionProvider | null;
  franchiseGroupIds?: number[] | null;
}

export interface LinkSessionMetadataInput {
  accounts: PlaidAccountInput[];
  institutionId: string;
}

export interface NumberRangeInput {
  maximum?: number | null;
  minimum?: number | null;
}

export interface OnboardUserInput {
  email: string;
  firstName: string;
  inviteCode?: string | null;
  lastName: string;
  phoneNumber: string;
}

export interface PersonalApplicationSensitiveInput {
  personalApplicationId: string;
  tin: string;
}

export interface PlaidAccountInput {
  id: string;
  mask: string;
  name: string;
  subtype: string;
  type: string;
}

export interface PlaidLinkInput {
  linkSessionMetadata: LinkSessionMetadataInput;
  plaidPublicToken: string;
}

export interface PlaidReLinkInput {
  linkSessionMetadata: LinkSessionMetadataInput;
  plaidPublicToken: string;
}

export interface StoreLocationInput {
  checkFieldIdentifier?: string | null;
  name: string;
  posFieldIdentifier?: string | null;
  storeId: string;
}

export interface StoreLocationUpdateInput {
  checkFieldIdentifier?: string | null;
  name?: string | null;
  posFieldIdentifier?: string | null;
  storeId?: string | null;
}

export interface SubmitAmplifyAccountApplicationInput {
  alloyBlackboxToken: string;
  applicantFullName: string;
  applicationId: string;
  businessApplicationSensitiveInput: BusinessApplicationSensitiveInput;
  personalApplicationSensitiveInput: PersonalApplicationSensitiveInput[];
}

export interface TellerLinkInput {
  enrollmentId: string;
  institutionName: string;
  tellerAccessToken: string;
}

export interface TellerReLinkInput {
  tellerEnrollmentId: string;
}

export interface TransactionExportJobInput {
  filter?: TransactionFilterInput | null;
  organizationId: string;
  sort?: TransactionSortInput | null;
}

export interface TransactionFilterInput {
  amount?: NumberRangeInput | null;
  bankAccountIds?: number[] | null;
  categoryLabels?: DerivedTransactionCategory[] | null;
  dateRange?: DateRangeInput | null;
  franchiseGroupIds?: number[] | null;
  institutionIds?: string[] | null;
  keyword?: string | null;
  merchant?: string | null;
  type?: TransferType | null;
}

export interface TransactionSortInput {
  ascending: boolean;
  type: TransactionSortType;
}

export interface UpdateAddress {
  city?: string | null;
  country?: string | null;
  postalCode?: string | null;
  state?: string | null;
  streetAddressLine1: string;
  streetAddressLine2?: string | null;
}

export interface UpdateBusinessApplicationInput {
  businessDescription?: string | null;
  businessEstablishmentDate?: string | null;
  businessIdentificationNumber?: string | null;
  doingBusinessAsName?: string | null;
  id: string;
  incorporationState?: string | null;
  legalName?: string | null;
  legalStructure?: AmplifyAccountBusinessLegalStructure | null;
  mailingAddress?: UpdateAddress | null;
  naicsDescription?: string | null;
  naicsSectorCode?: string | null;
  phoneNumber?: string | null;
  physicalAddress?: UpdateAddress | null;
  urls: string[];
}

export interface UpdatePersonalApplicationInput {
  businessTitle?: string | null;
  citizenship?: string | null;
  dateOfBirth?: string | null;
  emailAddress?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  mailingAddress?: UpdateAddress | null;
  middleName?: string | null;
  occupation?: string | null;
  ownershipPercentage?: number | null;
  phoneNumber?: string | null;
  physicalAddress?: UpdateAddress | null;
  roles?: AmplifyAccountApplicationRole[] | null;
  socialSecurityNumber?: string | null;
}

export interface UpdateUserEmailPreferencesInput {
  areEmailNotificationsEnabled: boolean;
  userId?: number | null;
}

export interface UpdateUserInfoInput {
  email?: string | null;
  firstName?: string | null;
  franchiseId?: string | null;
  isEmailVerified?: boolean | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  role?: Role | null;
}

export interface WireParams {
  accountNumber: string;
  address: updateAddressUnderscore;
  bankAddress: updateAddressUnderscore;
  bankName: string;
  routingNumber: string;
}

export interface updateAddressUnderscore {
  city: string;
  country: string;
  postal_code: string;
  state: string;
  street_line_1: string;
  street_line_2?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
