import { gql } from '@apollo/client'
import { AUTHORIZATION_FRAGMENT } from '../../fragments/AuthorizationFragment'
import { TEAM_MEMBER_FRAGMENT } from '../../fragments/TeamMemberFragment'

export const GET_SETTINGS_PAGE_DATA = gql`
    ${AUTHORIZATION_FRAGMENT}
    ${TEAM_MEMBER_FRAGMENT}
    query GetSettingsPageData($organizationId: String!) {
        currentUser {
            id
            email
            roles {
                franchiseGroup {
                    id
                    name
                }
            }
            authorization(organizationId: $organizationId) {
                ...AuthorizationFragment
            } 
            rolloutConfiguration {
                enableStripeCheckout
                enableStoreLocation
            }
            selectedOrganization(organizationId: $organizationId) {
                id
                subscriptionLevel
                franchiseGroups {
                    id
                    name
                    members {
                        ...TeamMemberFragment
                    }
                    subscription {
                        product {
                        name
                        id
                        features
                        }
                        quantity
                        status
                        stripeId
                    }
                    financialAccounts {
                        accountId
                        name
                        lastFour
                        status
                        accountSubTypeFormatted
                        plaidAccessToken
                        institution {
                            name
                            logoAssetUrl
                        }
                        plaidItemId
                        accountType
                        connectionProvider
                    }
                    hasBrokenAccountLink
                    storeLocations {
                        checkFieldIdentifier
                        id
                        name
                        posFieldIdentifier
                        storeId
                }
                }
            }
           
        }
    }
`
