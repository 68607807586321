import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import AmplifyAccountPromoCard from './AmplifyAccountPromoCard'
import ActiveAmplifyAccountCard from './ActiveAmplifyAccountCard'
import { GET_AMPLIFY_ACCOUNT_CARD_DATA } from '@/graphql/queries/GetTreasuryPageData'
import {
  type GetAmplifyAccountCardDataVariables,
  type GetAmplifyAccountCardData
} from '@/graphql/__generated__/GetAmplifyAccountCardData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'

export interface AmplifyAccountCardComponentProps {
  organizationId: string
  franchiseGroupId: number
  totalBalance?: number
}

export default function AmplifyAccountCardComponent (
  { organizationId, franchiseGroupId, totalBalance }: AmplifyAccountCardComponentProps
): ReactElement {
  const {
    data,
    error,
    loading
  } = useQuery<GetAmplifyAccountCardData, GetAmplifyAccountCardDataVariables>(GET_AMPLIFY_ACCOUNT_CARD_DATA,
    {
      variables: {
        organizationId,
        franchiseGroupId
      }
    }
  )

  const doesAmplifyAccountExist = data?.amplifyAccount != null

  return (
    <Flex flexDirection='column' width='100%' gap={3}>
      <Text>Amplify Account</Text>
      <AltirSkeleton isLoading={loading} error={error}>
        {doesAmplifyAccountExist
          ? <ActiveAmplifyAccountCard franchiseGroupId={franchiseGroupId} data={data}/>
          : <AmplifyAccountPromoCard data={data} totalBalance={totalBalance}/>}
      </AltirSkeleton>
    </Flex>
  )
}
