import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import TeamSettingsComponent from './team_settings/TeamSettingsComponent'
import AccountsSettingsComponent from './accounts/AccountsSettingsComponent'
import AmplifyAccountSettingsComponent from './amplify/AmplifyAccountSettingsComponent'
import BusinessInfoComponent from './BusinessInfoComponent'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import UserIcon from '@/library/icons/UserIcon'
import { Color } from '@/theme/theme'
import {
  type GetSettingsPageDataVariables,
  type GetSettingsPageData
} from '@/graphql/__generated__/GetSettingsPageData'
import { GET_SETTINGS_PAGE_DATA } from '@/graphql/queries/settings/GetSettingsPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import AccountsIcon from '@/library/icons/AccountsIcon'
import AltirTransparentIcon from '@/library/icons/AltirTransparentIcon'
import {
  AuthAction,
  getUserRoleForFranchiseContext,
  hasAccess,
  INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY
} from '@/utils/authUtils'
import StoreLocationSettingsComponent
  from '@/components/pages/settings/components/store_locations/StoreLocationSettingsComponent'
import LocationIcon from '@/library/icons/LocationIcon'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { AuthResource } from '@/graphql/__generated__/globalTypes'
import { useNavigationState } from '@/hooks/useNavigationState'

interface BusinessSettingsNavigationState {
  isUserSettingsSelected: boolean | undefined
  isStoreLocationSelected: boolean | undefined
  isLinkedAccountsSelected: boolean | undefined
  isAmplifyAccountSelected: boolean | undefined
}

export default function BusinessSettingsComponent (): ReactElement {
  const stateOnNavigate = useNavigationState<BusinessSettingsNavigationState>()
  const {
    selectedOrganizationId,
    selectedFranchiseGroupId
  } = useAltirStore(state => ({
    selectedOrganizationId: state.selectedOrganizationState.selectedOrganizationId,
    selectedFranchiseGroupId: state.selectedFranchiseGroupId
  }))

  const isUserSettingsPreSelected = stateOnNavigate?.isUserSettingsSelected ?? false
  const isStoreLocationPreSelected = stateOnNavigate?.isStoreLocationSelected ?? false
  const isLinkedAccountsPreSelected = stateOnNavigate?.isLinkedAccountsSelected ?? false
  const isAmplifyAccountPreSelected = stateOnNavigate?.isAmplifyAccountSelected ?? false
  const { loading, error, data, refetch } = useQuery<GetSettingsPageData, GetSettingsPageDataVariables>(
    GET_SETTINGS_PAGE_DATA,
    {
      variables: {
        organizationId: selectedOrganizationId
      }
    }
  )

  const franchiseGroup = data?.currentUser?.selectedOrganization?.franchiseGroups?.find(
    franchiseGroups => Number(franchiseGroups.id) === selectedFranchiseGroupId
  )

  const membersWithRoles = franchiseGroup?.members
    ?.map(user => {
      return {
        ...user,
        id: Number(user.id),
        businessRole: {
          businessId: Number(franchiseGroup.id),
          name: franchiseGroup.name,
          role: getUserRoleForFranchiseContext(user.roles, Number(franchiseGroup.id)) ?? null
        },
        subRoles: []
      }
    })

  // Authorization checks
  const canManageTeamSettings = hasAccess(
    AuthResource.USER, AuthAction.WRITE, data?.currentUser?.authorization ?? []
  )
  const canManageStoreSettings = hasAccess(
    AuthResource.STORE_LOCATION, AuthAction.WRITE, data?.currentUser?.authorization ?? []
  )
  const canManageAmplifySettings = hasAccess(
    AuthResource.AMPLIFY_ACCOUNT, AuthAction.WRITE, data?.currentUser?.authorization ?? []
  )
  const canManageLinkedAccounts = hasAccess(
    AuthResource.BANK_ACCOUNT, AuthAction.WRITE, data?.currentUser?.authorization ?? []
  )

  // rollout configurations
  const isStoreLocationEnabled = data?.currentUser?.rolloutConfiguration?.enableStoreLocation === true

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Flex flexDirection='column' width='100%' gap={6}>
        <BusinessInfoComponent
          selectedFranchiseGroup={franchiseGroup ?? undefined}
        />
        <Flex flexDir='column' gap={2}>
          <DropDownComponent
            title='Team'
            icon={<UserIcon color={Color.DARK_BLUE}/>}
            isOpenOnStart={isUserSettingsPreSelected}
            dropDownContent={<TeamSettingsComponent
              members={membersWithRoles ?? []}
              refetchSettings={refetch}
              canManageTeamSettings={canManageTeamSettings}
                             />}
          />
          {isStoreLocationEnabled && <DropDownComponent
            title='Store Locations'
            icon={<LocationIcon color={Color.DARK_BLUE}/>}
            isOpenOnStart={isStoreLocationPreSelected}
            dropDownContent={
              <StoreLocationSettingsComponent
                storeLocations={franchiseGroup?.storeLocations ?? []}
                refetchSettings={refetch}
                canManageStoreLocationSettings={canManageStoreSettings}
                franchiseGroupId={Number(franchiseGroup?.id)}
              />}
                                     />}
          <DropDownComponent
            title='Amplify Account'
            icon={<AltirTransparentIcon color={Color.DARK_BLUE}/>}
            isOpenOnStart={isAmplifyAccountPreSelected}
            dropDownContent={
              <AmplifyAccountSettingsComponent />
            }
            isDisabled={!canManageAmplifySettings}
            disabledTooltipText={!canManageAmplifySettings ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
          />
          <DropDownComponent
            title='Linked Accounts'
            icon={<AccountsIcon color={Color.DARK_BLUE}/>}
            isOpenOnStart={isLinkedAccountsPreSelected}
            dropDownContent={
              <AccountsSettingsComponent
                franchiseGroupId={selectedFranchiseGroupId}
                accounts={franchiseGroup?.financialAccounts ?? []}
                refetchSettings={refetch}
              />
            }
            hasNotification={franchiseGroup?.hasBrokenAccountLink}
            isDisabled={!canManageLinkedAccounts}
            disabledTooltipText={!canManageLinkedAccounts ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
          />
        </Flex>
      </Flex>
    </AltirSkeleton>
  )
}
