import React, { useState, type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import AmplifyBusinessDropDownComponent from './components/AmplifyBusinessDropDownComponent'
import OnboardingPage from '@/library/page/OnboardingPage'
import {
  type UpdateOrganizationOnboardingState,
  type UpdateOrganizationOnboardingStateVariables
} from '@/graphql/__generated__/UpdateOrganizationOnboardingState'
import { UPDATE_ORGANIZATION_ONBOARDING_STATE } from '@/graphql/mutations/onboarding/UpdateOrganizationOnboardingState'
import { RouteParam, ROUTES } from '@/api/routes'
import Button from '@/library/button/Button'
import ErrorInline from '@/library/errors/ErrorInline'
import {
  GET_ONBOARDING_BUSINESS_AMPLIFY_APPLICATION_DATA
} from '@/graphql/queries/onboarding/GetOnboardingBusinessAmplifyApplicationData'
import {
  type GetOnboardingBusinessAmplifyApplicationData
} from '@/graphql/__generated__/GetOnboardingBusinessAmplifyApplicationData'
import { areAllAmplifyApplicationsSubmitted } from '@/utils/amplifyApplicationUtils'
import { useSkipAmplifyApplicationCallToAction } from '@/hooks/onboarding/useSkipOnboardingAmplifyAppCallToAction'
import { useAmplifyAppSuccessToast } from '@/hooks/onboarding/useAmplifyAppSuccessToast'
import { routeWithParams } from '@/utils/routerUtils'
import { ContainerWidth } from '@/theme/theme'
import { type ErrorWithContent } from '@/types/types'

export default function OnboardingAmplifyOverviewPage (): ReactElement {
  const navigate = useNavigate()
  useAmplifyAppSuccessToast()
  const [organizationError, setOrganizationError] = useState<Error | undefined>()
  const [
    completeAmplifyOnboardingError,
    setCompleteAmplifyOnboardingError
  ] = useState<ErrorWithContent | undefined>()

  const {
    data,
    error,
    loading
  } = useQuery<GetOnboardingBusinessAmplifyApplicationData>(GET_ONBOARDING_BUSINESS_AMPLIFY_APPLICATION_DATA)
  const skipCallToAction = useSkipAmplifyApplicationCallToAction()

  const [
    completeAmplifyOnboarding,
    { loading: completeAmplifyOnboardingLoading }
  ] = useMutation<UpdateOrganizationOnboardingState, UpdateOrganizationOnboardingStateVariables>(
    UPDATE_ORGANIZATION_ONBOARDING_STATE,
    {
      onCompleted: () => { navigate(ROUTES.V2_SIGN_UP_PRO) },
      onError: (error) => {
        setCompleteAmplifyOnboardingError({
          customContent: {
            title: 'Something went wrong',
            subtitle: 'Unable to proceed. Your applications have been saved. Please try again or contact support.'
          },
          error
        })
      }
    }
  )

  function onContinue (): void {
    const organizationId = data?.currentUser?.selectedOrganization?.id
    if (organizationId == null) {
      setOrganizationError(Error('Organization id is null.'))
      return
    }
    void completeAmplifyOnboarding({
      variables: {
        isAmplifyOnboardingComplete: true,
        organizationId
      }
    })
  }

  const businesses = data?.currentUser?.selectedOrganization?.franchiseGroups ?? []

  return (
    <OnboardingPage
      maxWidth={ContainerWidth.LARGE}
      callToAction={skipCallToAction}
      isLoading={loading}
      error={error}
    >
      <Center flexDir='column' gap={1} textAlign='center' w='100%'>
        <Heading>Set up your Amplify Accounts</Heading>
        <Text>
          Apply to open your Amplify Account(s).
          Your information will be securely encrypted and sent to our banking partner.
          We will save your progress if you skip this step.
        </Text>
      </Center>
      <Center flexDir='column' gap={2} w='100%'>
        {
          businesses.map((business, index) => {
            const callToActionClick = (): void => {
              navigate(
                routeWithParams(
                  ROUTES.V2_SIGN_UP_AMPLIFY_APP,
                  [{ param: RouteParam.BUSINESS_ID, value: business.id }])
              )
            }
            return (
              <AmplifyBusinessDropDownComponent
                key={index}
                business={business}
                callToActionClick={callToActionClick}
              />
            )
          })
        }
      </Center>
      <Button
        text='Continue'
        onClick={onContinue}
        isDisabled={!areAllAmplifyApplicationsSubmitted(businesses)}
        isLoading={completeAmplifyOnboardingLoading}
      />
      <ErrorInline error={completeAmplifyOnboardingError ?? organizationError}/>
    </OnboardingPage>
  )
}
